import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import normalize from "../utils/tools";
import SEO from "../components/seo"

export default ({ data }) => {
  const post = data.nodePage
  let article_body_elements = normalize(post.body.processed, data.allFileFile)

  return (
    <Layout>
      <SEO title={post.title} description={post.body.summary}/>
      <section>
        <header className="main">
          <h1>{ post.title }</h1>
        </header>
        <div>{article_body_elements}</div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    nodePage(id: { eq: $id }) {
      title
      body {
        summary
        processed
      }
    }
    allFileFile {
      edges {
        node {
        uri {
          url
        }
          localFile {
            childImageSharp {
              fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  }
`